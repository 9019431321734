<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="primary white--text">Imágenes</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="loading">
                <v-sheet>
                  <v-skeleton-loader
                    type="table-row, table-row, table-row, table-row"
                  ></v-skeleton-loader>
                </v-sheet>
              </v-col>
              <v-col v-for="url in images"
                     v-else
                     :key="url"
                     class="d-flex child-flex pa-2"
                     cols="12"
                     md="3"
                     sm="4"
              >
                <v-img
                  :src="url"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      align="center"
                      class="fill-height ma-0"
                      justify="center"
                    >
                      <v-progress-circular
                        color="grey lighten-5"
                        indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OrderImages",
  data: () => ({
    loading: true,
    images: [],
  }),
  created() {
    const id = this.$route.params['id'];
    this.$store.commit('LOADER', true)
    this.$axios.get(`/api/v1/apk/order/${id}/images`)
      .then(({ data }) => this.images = data)
      .catch(reason => {
        console.error(reason)
      })
      .finally(() => {
        this.$store.commit('LOADER', false)
        this.loading = false
      })
  }
}
</script>

<style lang="sass" scoped>
.v-image
  border-radius: 5px
</style>